import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import login from './images/login.svg';
import discount from './images/online.svg';

export default class FlowSlider extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {

        var settings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };


        return (
            <div>
                <Slider {...settings}>
                    <div className={'slideContainer'} >
                        <img src={login}/>
                        <h2>
                            Login with your Facebook id
                        </h2>
                    </div>
                    <div className={'slideContainer'}>
                        <img  src={discount}/>
                        <h2>
                            Get you code via e-mail
                        </h2>
                    </div>
                </Slider>
            </div>
        )
    }
}